import 'slick-carousel';
// import 'slick-carousel/slick/slick.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// import 'slick-carousel/slick/slick-theme.css';
import "./sass/style.scss";
import 'viewport-extra';
import simpleParallax from 'simple-parallax-js';
import 'jquery.easing/jquery.easing';
import { Luminous,LuminousGallery } from 'luminous-lightbox';
import 'modaal';

/* ----------------------------------
ローディング
---------------------------------- */
const loading = document.getElementById('loading');

/* 読み込み完了 */
window.addEventListener('load', stopload);

/* 6秒経ったら強制的にロード画面を非表示にする */
setTimeout(stopload,10000, '');

/* ロード画面を非表示にする処理 */
function stopload(){
  loading.classList.add('is-loaded');
  if(document.querySelector('.firstView') ) {
    const firstview = document.getElementById('firstView');
    firstview.classList.add('is-loaded');
  }
  AOS.init({
    offset: 50,
    once: true,
  });
}

$(function(){
  $('.modaal').modaal();

  $('.modalSearch_tab_item').click(function() {
    var index = $('.modalSearch_tab .modalSearch_tab_item').index(this);
    $('.modalSearch_tab_item, .modalSearch_panel').removeClass('active');
    $(this).addClass('active');
    $('.modalSearch_panelArea .modalSearch_panel').eq(index).addClass('active');
  });

  $('.creatorCategory_tab_item').click(function() {
    var index = $('.creatorCategory_tab .creatorCategory_tab_item').index(this);
    $('.creatorCategory_tab_item, .creatorCategory_panel').removeClass('active');
    $(this).addClass('active');
    $('.creatorCategory_panelArea .creatorCategory_panel').eq(index).addClass('active');
  });

  $('.creator_works_category_item').click(function() {
    var index = $('.creator_works_category .creator_works_category_item').index(this);
    $('.categoryTab_item_link, .creator_works_category_panel').removeClass('is-current');
    $(this).children('.categoryTab_item_link').addClass('is-current');
    $('.creator_works_category_panelArea .creator_works_category_panel').eq(index).addClass('is-current');
  });

  $('.index_area_group_parent').click(function() {
    var windowWidth = window.innerWidth;
    if(windowWidth < 1200){
      $(this).toggleClass('active');
      $(this).next('.index_area_group_children').stop().slideToggle();
    }
  });

  $('.faqList_q').click(function() {
    $(this).toggleClass('active');
    $(this).next('.faqList_a').stop().slideToggle();
  });

  $('.nav-toggle').on('click', function () {
    $('.gNav_toggle').toggleClass('active');
    $('.gNav_overlay').toggleClass('open');
    $('.siteHead').toggleClass('visible');
    if ($("body").css('overflow') == 'hidden') {
      $("body").css({ overflow: 'visible' });
    } else if ($("body").css('overflow') == 'visible') {
      $("body").css({ overflow: 'hidden' });
    };
  });

  if(document.querySelector('.firstView') ) {
    $(window).scroll(function () {
      if($(window).scrollTop() > 1) {
        $('.siteHead').addClass('is-white');
      } else {
        $('.siteHead').removeClass('is-white');
      }
    });
  }

  const optionInfinite = {
    infinite: true,
    arrows: false,
    swipe: false,
    dots: false,
    variableWidth: true,  // スライド幅の自動計算を無効
    autoplay: true,
    autoplaySpeed: 0,
    speed: 30000,
    cssEase: "linear",
  }

  const slickInfinite = $('.works_slider');
  $(slickInfinite).slick(optionInfinite);

  $('.index_blogList').slick({
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    fade: false,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          // centerMode: true,
          // centerPadding: '60px',
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          // centerMode: true,
          // centerPadding: '30px',
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });


  // #で始まるアンカーをクリックした場合に処理
  $('.smoothScroll').click(function(){
    // 移動先を50px上にずらす
    const adjust = 90;
    // スクロールの速度
    const speed = 400; // ミリ秒
    // アンカーの値取得
    const href= $(this).attr("href");
    // 移動先を取得
    const target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を調整
    const position = target.offset().top - adjust;
    // スムーススクロール
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

  $('.search_selector_title').on('click', function () {
    $(this).toggleClass('active');
    $(this).next('.search_selector_toggle').toggleClass('open');
    $(this).next('.search_selector_toggle').stop().slideToggle();
  });

  $(".search_selector_group dt input[type='checkbox']").change(function () {                   // dtのinput（都道府県）が変更された時
    var area = $(this).parents(".search_selector_group").find("dd input[type='checkbox']");    // 選択された都道府県の市区町村を変数に格納
    var parentBg = $(this).parents(".search_selector_group_parent");
    if ($(this).is(":checked")) {          // チェックがついた時は、、、
      area.prop("checked",true);           // 選択した都道府県の市区町村全てをチェックする
      parentBg.addClass('is-active');
    } else {
      area.prop("checked",false);          // 選択した都道府県の市区町村のチェックを全て外す
      parentBg.removeClass('is-active');
    }
  });

  $(".search_selector_group dd input[type='checkbox']").change(function () {  // ddのinput（市区町村）が変更された時
    var length = $(this).parents(".search_selector_group").find("dd").length; // 兄弟要素（ほかの市区町村）を含めて何個あるか数えて変数に入れる
    var checked_length = $(this).parents(".search_selector_group").find("dd input:checked").length;  // 既にチェックがついている市区町村が何個か数えて変数に入れる
    var pref = $(this).parents(".search_selector_group").find("dt input");  //選択された市区町村の親の都道府県を変数に格納
    var parentBg = $(this).parents(".search_selector_group").find(".search_selector_group_parent");
    if(checked_length < length && checked_length != 0){                   // もしチェックが0件だったら
      pref.prop("indeterminate",true);            // 都道府県のチェックを外す
      pref.prop("checked",false);             // 都道府県のチェックを付ける
      parentBg.removeClass('is-active');
    } else if(checked_length == 0) {    // もしチェックが全てついていたら
      pref.prop("indeterminate",false);             // 都道府県のチェックを付ける
      pref.prop("checked",false);             // 都道府県のチェックを付ける
      parentBg.removeClass('is-active');
    } else if(length == checked_length) {    // もしチェックが全てついていたら
      pref.prop("indeterminate",false);             // 都道府県のチェックを付ける
      pref.prop("checked",true);             // 都道府県のチェックを付ける
      parentBg.addClass('is-active');
    }
  });

  $(window).on('load', function() {
    $(".search_selector_group").each(function(i, o){
      var length = $(this).find("dd").length; // 兄弟要素（ほかの市区町村）を含めて何個あるか数えて変数に入れる
      var checked_length = $(this).find("dd input:checked").length;  // 既にチェックがついている市区町村が何個か数えて変数に入れる
      var pref = $(this).find("dt input");  //選択された市区町村の親の都道府県を変数に格納
      var parentBg = $(this).find(".search_selector_group_parent");
      if(checked_length < length && checked_length != 0){                   // もしチェックが0件だったら
        pref.prop("indeterminate",true);            // 都道府県のチェックを外す
        pref.prop("checked",false);             // 都道府県のチェックを付ける
        parentBg.removeClass('is-active');
      } else if(checked_length == 0) {    // もしチェックが全てついていたら
        pref.prop("indeterminate",false);             // 都道府県のチェックを付ける
        pref.prop("checked",false);             // 都道府県のチェックを付ける
        parentBg.removeClass('is-active');
      } else if(length == checked_length) {    // もしチェックが全てついていたら
        pref.prop("indeterminate",false);             // 都道府県のチェックを付ける
        pref.prop("checked",true);             // 都道府県のチェックを付ける
        parentBg.addClass('is-active');
      }
    });
  });

  $('.search_reset').on('click', function(){
    $('.search_text').val('');
    $('.checkbtn-input').prop('checked', false);
    $('.checkbtn-input').prop("indeterminate",false);
  });

  if($('.simplefavorite-button.active').length) {
    $('.simplefavorite-button.active').parents('.work_creator_fav').addClass('active');
  }

  $('.simplefavorite-button').click(function(){
    if ($(this).hasClass('active')) {
      $(this).parents('.work_creator_fav').removeClass('active');
    }else{
      $(this).parents('.work_creator_fav').addClass('active');
    }
  });
});





/* ----------------------------------
画面高さをCSS変数に代入（100vh用）
---------------------------------- */

const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

let vw = window.innerWidth;

window.addEventListener('resize', () => {
  if (vw === window.innerWidth) {
    // 画面の横幅にサイズ変動がないので処理を終える
    return;
  }

  // 画面の横幅のサイズ変動があった時のみ高さを再計算する
  vw = window.innerWidth;
  setFillHeight();
});

// 初期化
setFillHeight();

/* ----------------------------------
ヘッダー動作
---------------------------------- */
const header = document.querySelector('.siteHead');
const headerFv = document.querySelector('.headFv');
if(document.querySelector('.headFv') ) {
  const headVisibleOptions = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
  };
  const headVisibleObserver = new IntersectionObserver(doVisible, headVisibleOptions);
  headVisibleObserver.observe(document.querySelector('.firstviewArea'));
  function doVisible(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        header.classList.add('in-firstview');
        header.classList.remove('out-firstview');
        headerFv.classList.add('in-firstview');
        headerFv.classList.remove('out-firstview');
      }else{
        header.classList.remove('in-firstview');
        header.classList.add('out-firstview');
        headerFv.classList.remove('in-firstview');
        headerFv.classList.add('out-firstview');
      }
    });
  }
};

/* ----------------------------------
ヘッダー動作
---------------------------------- */
var galleryOpts = {
  // Whether pressing the arrow keys should move to the next/previous slide.
  arrowNavigation: true,
};

var luminousOpts = {
  // These options have the same defaults and potential values as the Luminous class.
};

new LuminousGallery(document.querySelectorAll('.lumiGallery'), galleryOpts, luminousOpts);